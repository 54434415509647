<template>
  <div class="app">
    <el-dialog
      title="订单发货"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleSendClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item
          label="最低惠豆"
          prop="beanMin"
          :rules="[
            { required: true, message: '请输入最低惠豆', trigger: 'blur' },
          ]"
        >
          <el-input
            type="text"
            v-model="form.beanMin"
            maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="最高惠豆"
          prop="beanMax"
          :rules="[
            { required: true, message: '请输入最高惠豆', trigger: 'blur' },
          ]"
        >
          <el-input
            type="text"
            v-model="form.beanMax"
            maxlength="10"
            value="0"
          ></el-input>
        </el-form-item>
        <!--<el-form-item label="排序" prop="ordering" :rules="[{required:true,message:'请输入排序',trigger: 'blur' }]">
                    <el-input type="number" v-model="form.ordering"  maxlength="5" value="0"></el-input>
                </el-form-item>-->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSendClose">取 消</el-button>
        <el-button type="primary" @click="send('form')">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    row: Object,
  },
  data() {
    return {
      formCancel: "",
      isShowUpload: false,
      form: {
        beanMin: "",
        beanMax: "",
        sort: "0",
      },
      dialogVisible: true,
      formSend: "",
      logisticsInfos: {},
      upload: {
        type: { fileType: "category" },
      },
    };
  },
  methods: {
    send(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.form.file = "";
              let url = this.$api.beansectioEdit;
              if (this.$isEmpty(this.form.id)) {
                url = this.$api.beansectioAdd;
              }
              this.post(url, this.form);
              this.handleSendClose();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    sendDialog() {
      this.form = this.row;
      this.isShowUpload = false;
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          location.reload();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    handleSendClose() {
      this.dialogVisible = false;
      this.$emit("dialog", false);
    },
  },
  created() {
    this.sendDialog();
  },
};
</script>
<style lang="scss" scoped>
</style>
