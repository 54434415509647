<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>设备管理</el-breadcrumb-item>
          <el-breadcrumb-item>设备类型</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-button
                type="primary"
                @click="$router.push({ name: 'typeAdd' })"
                icon="el-icon-plus"
                >添加设备类型
              </el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
          :data="list"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="deviceImageUrl" label="设备图片">
            <template slot-scope="scope">
              <el-image
                :src="getImage(scope.row.deviceImageUrl)"
                fit="scale-down"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="deviceType" label="设备类型"></el-table-column>
          <el-table-column
            prop="deviceModel"
            label="设备型号"
          ></el-table-column>
          <el-table-column prop="upDate" label="上市时间"></el-table-column>
          <el-table-column prop="cashPledge" label="押金"></el-table-column>
          <el-table-column
            prop="deviceCount"
            label="当前设备数量"
          ></el-table-column>
          <el-table-column
            prop="deviceRefundRuleDTO"
            label="退押金条件"
            :render-header="renderHeader"
          >
            <template>
              <el-row>
                <el-col>
                  <span>普通支付：30000元</span>
                </el-col>
                <el-col>
                  <span>惠豆支付：1000元</span>
                </el-col>
                <el-col>
                  <span :disabled="true" class="endDate"
                    >截止日期：2020年05月21日</span
                  >
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="创建时间"></el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="setDialog(scope.row, 'edit')"
                >编辑
              </el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="del(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
        <add
          :row="infoDetail"
          @dialog="getDialog"
          v-if="dialogAddVisible"
        ></add>
      </div>
    </div>
  </div>
</template>

<script>
import add from "@/components/admin/mall/query/add.vue";
import { staticUrl } from "@/plugins/api";

export default {
  components: {
    add,
  },
  data() {
    return {
      list: [],
      selectId: "",
      loading: false,
      dialogAddVisible: false,
      dialogChildrenVisible: false,
      infoDetail: {},
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      goods_verify_count: 0,
      where: {
        goods_name: "",
        limit: 0,
        page: 1,
        parentId: 0,
      },
    };
  },
  methods: {
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    getList() {
      this.loading = true;
      this.where.page = this.currentPage;
      this.where.limit = this.pageSize;
      this.$post(this.$api.deviceTypeList, this.where).then((res) => {
        if (res.code == 1) {
          this.totalData = res.data.count;
          this.list = res.data.data;
          this.list.forEach((rs, index) => {
            this.list[index].hasChildren = true;
          });
        }
        this.loading = false;
      });
    },
    // 删除处理
    del(id) {
      if (this.$isEmpty(id)) {
        return this.$message.error("请先选择删除的对象");
      }
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$postFormData(this.$api.beansectioDel, { id: id }).then(
            (res) => {
              if (res.code == 1) {
                this.getList();
                this.$router.go(0);
                this.$message.success("删除成功");
              } else {
                this.$message.error("删除失败");
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    currentChange(e) {
      this.currentPage = e;
      this.getList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    getDialog(val) {
      this.dialogAddVisible = val;
      this.dialogChildrenVisible = val;
    },
    setDialog(val, type) {
      this.infoDetail = val;
      this.infoDetail.type = type;
      this.dialogAddVisible = true;
    },
    // render 事件
    renderHeader(h, { column }) {
      let text =
        "退押金信息：在xxxx年xx月xx日前，0.38普通聚合支付达到XXX元，\n\n 或赠送悦豆达XXX个后，缴纳的设备押金可退还.";
      return h("div", [
        h("span", column.label),
        h("el-tooltip", [
          h("i", {
            class: "el-icon-question",
            style: "color:#409eff;margin-left:5px;",
          }),
          h(
            "div",
            {
              slot: "content",
              style: "width: 200px;",
            },
            text
          ),
        ]),
      ]);
    },
    getImage(val) {
      return staticUrl + val;
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.endDate {
  color: #c0c4cc;
}
.el-image {
  height: 100px;
  width: 100px;
}
</style>
